<template>
  <div>
    <div class="c-company">
      <div class="logo">
        <img :src="img" alt="">
      </div>
      <div class="con" id="btn-copy" @click="copyOrderId2">
        <div class="box">
          <span class="uc-blue">微企宣</span>
          <i class="uc-gray">点击复制去微信中粘贴并关注</i>
        </div>
        <div class="txt">关注微信公众号获取更多企业权益</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ucCompany',
  components: {},
  data() {
    return {
      img: require("../assets/images/logo@2x.png")
    }
  },
  mounted() { },
  methods: {
    copyOrderId2() {
      this.$copyText('微企宣').then(() => {
        this.$toasted.success('复制成功', { position: 'top-center', duration: 2000 })
      })
    },
  },
}
</script>

<style scoped>
.c-company {
  border-radius: 0.05rem;
  background-color: #fff;
  margin: 20px auto 0.3rem;
  -webkit-box-shadow: 0 -0.05rem 0.24rem 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 -0.05rem 0.24rem 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 355px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.uc-blue {
  font-size: 18px;
  font-family: MiSans-Semibold, MiSans;
  font-weight: 600;
  color: #3E73FE;
}

.c-company .logo {
  border-radius: 0.05rem;
  overflow: hidden;
  margin-right: 0.3rem;
}

.c-company .logo img {
  width: 50px;
  height: 50px;
  display: block;
  -o-object-fit: cover;
  object-fit: cover;
}

.c-company .con {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-width: 0;
}

.c-company .box {
  margin-bottom: 0.2rem;
  margin-top: -8px;
}

.c-company .box .uc-gray {
  margin-left: 0.2rem;
  font-size: 12px;
  font-family: MiSans-Medium, MiSans;
  font-weight: 500;
  color: #BFBFBF;
}

.txt {
  font-size: 14px;
  font-family: MiSans-Medium, MiSans;
  font-weight: 500;
  color: #3D3D3D;
}
</style>
